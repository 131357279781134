import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import InfoIconThick from '../../util/icons/components/InfoIconThick';

class InfoModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showingModal: false,
      startPosition: null
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal () {
    this.setState({
      showingModal: true,
      startPosition: window.scrollY
    });
  }
  hideModal () {
    this.setState({ showingModal: false }, () => {
      window.scrollTo({
        top: this.state.startPosition,
        behavior: 'smooth'
      });
    });
  }
  render () {
    const modal = this.state.showingModal ? (
      <Modal contentClass={ this.props.contentClass }
        contentTestId={ this.props.contentTestId }
        close={ this.hideModal } pos={ this.state.startPosition }>
        <h4 className='mini-title'>find out more...</h4>
        { this.props.children }
      </Modal>
    ) : null;
    let className = this.props.questionMarkClass ?
      this.props.questionMarkClass + ' bb-info-icon' : 'bb-info-icon';
    const infoIcon = this.props.fat ? (
      <InfoIconThick testId={ this.props.iconTestId }
        className={ className }
        onClick={ this.showModal } />
    ) : (
      <div
        data-cy={ this.props.iconTestId }
        className={ className }
        onClick={ this.showModal }>i</div>
    );
    return (
      <Fragment>
        { modal }
        { infoIcon }
        {/* <QuestionMarkIcon
          className={ this.props.questionMarkClass }
          onClick={ this.showModal } /> */}
      </Fragment>
    );
  }
}

InfoModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  contentClass: PropTypes.string,
  questionMarkClass: PropTypes.string,
  contentTestId: PropTypes.string,
  iconTestId: PropTypes.string,
  fat: PropTypes.bool
};

export default InfoModal;
