import React from 'react';
import PropTypes from 'prop-types';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import GoodBeefScale from '../../util/figures/GoodBeefScale';
import { Link } from 'react-router-dom';
import './qr-grade-section.scss';

const QRGradeScaleSection = ({ score }) => {
  return (
    <article id='qr-grade-scale-section'>
      <h2>
        Grading Premium Quality Beef
        <Link to="/about/grading"><InfoIconThick /></Link>
        <div className="scroll-point" id="grade-section-scroll-point"></div>
      </h2>
      <GoodBeefScale score={ score } />
    </article>
  );
};

QRGradeScaleSection.propTypes = {
  score: PropTypes.number.isRequired
};

export default QRGradeScaleSection;
