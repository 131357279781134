import React, { Component } from 'react';
import { connect } from 'react-redux';
import './qr-venn-mobile.scss';
import PropTypes from 'prop-types';
import DropDownIcon from '../../util/icons/components/DropDownIcon';
import {
  getFactorDef,
  calculateElementScore } from '../../admin/scoreDefinitions/util';
import GBIVennConceptShapes, {
  getTriggerAreaTop,
  getTriggerAreaBottom
} from '../../util/figures/GBIVennConceptShapes';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';

const elements = ['production', 'eating', 'environment'];

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

/* eslint-disable max-len */
class QRVennMobile extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: props.view || 'core', // 'core' or 'concept',
      element: null, // 'production', 'eating', 'environment'
      autoRotate: true,
      animationFinished: false
    };
    this.sheildRef = React.createRef();
    this.shapesRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.update = this.update.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowResize);
    this.startTimeout = (() => {
      this.handleScroll();
      this.handleWindowResize();
    }, 50);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.startTimeout) clearTimeout(this.startTimeout);
    if (this.showProductionTimeout) clearTimeout(this.showProductionTimeout);
    if (this.showEatingTimeout) clearTimeout(this.showEatingTimeout);
    if (this.showEnvironmentTimeout) clearTimeout(this.showEnvironmentTimeout);
    if (this.showProductionInterval) clearInterval(this.showProductionInterval);
    if (this.showEatingInterval) clearInterval(this.showEatingInterval);
    if (this.showEnvironmentInterval) clearInterval(this.showEnvironmentInterval);
  }
  getScore () {
    if (this.props.animal) {
      return this.props.animal.beefQualityIndex || 120;
    }
  }
  getShield () {
    if (!this.shield) {
      this.shield = document.getElementById('gbi-venn-concept-shield');
    }
    return this.shield;
  }
  setSelectedElement (element) {
    this.setState({ element, autoRotate: false });
  }
  getScoreElement () {
    if (!this.scoreElement) {
      this.scoreElement = document.getElementById('gbi-circles-concept-score');
    }
    return this.scoreElement;
  }
  clearTimeouts () {
    if (this.showProductionTimeout) {
      clearTimeout(this.showProductionTimeout);
    }
    if (this.showEatingTimeout) {
      clearTimeout(this.showEatingTimeout);
    }
    if (this.showEnvironmentTimeout) {
      clearTimeout(this.showEnvironmentTimeout);
    }
  }
  pageLeft () {
    const index = elements.indexOf(this.state.element);
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = 2;
    }
    this.setState({ element: elements[newIndex], autoRotate: false });
  }
  pageRight () {
    const index = elements.indexOf(this.state.element);
    let newIndex = index + 1;
    if (newIndex > 2) {
      newIndex = 0;
    }
    this.setState({ element: elements[newIndex], autoRotate: false });
  }
  handleWindowResize () {

    if (this.shapesRef.current) {
      const rect = this.shapesRef.current.getBoundingClientRect();
      this.triggerTop = getTriggerAreaTop(rect);
      this.triggerBottom = getTriggerAreaBottom(rect);
    } else {
      this.triggerTop = 0;
      this.triggerBotom = 0;
    }
  }
  handleScroll () {
    try {
      if (!this.triggerTop || !this.triggerBottom) this.handleWindowResize();
      if ((this.triggerTop >= window.scrollY) &&
      (this.triggerBottom <= (window.innerHeight + window.scrollY))) {
        if (!this.state.visibleTime) {
          this.setState({ visibleTime: new Date() });
        }
        this.update();
      } else {
        const view = this.props.view || 'core';
        this.setState({ view, autoRotate: true });
      }
    } catch (error) { /* */ }
  }
  update () {
    if (this.state.animationFinished) return;
    const runTime = 4000;
    const scoreElement = this.getScoreElement();
    if (!scoreElement) {
      return window.requestAnimationFrame(this.update);
    }
    const now = new Date();
    const timePassed = now - this.state.visibleTime;
    const rawScore = (timePassed / runTime) * this.getScore();
    const currentScore = Math.round(rawScore);
    if (currentScore < this.getScore()) {
      scoreElement.innerHTML = currentScore;
      window.requestAnimationFrame(this.update);
    } else {
      scoreElement.innerHTML = this.getScore();
      if (this.state.view !== 'concept' || !this.state.autoRotate) return;
      this.showProductionTimeout = setTimeout(() => {
        if (!this.state.autoRotate) return;
        this.setState({ element: 'production' });
        this.showProductionInterval = setInterval(() => {
          if (!this.state.autoRotate) return;
          this.setState({ element: 'production' });
        }, 15 * 1000);
      }, 100);
      this.showEatingTimeout = setTimeout(() => {
        if (!this.state.autoRotate) return;
        this.setState({ element: 'eating' });
        this.showEatingInterval = setInterval(() => {
          if (!this.state.autoRotate) return;
          this.setState({ element: 'eating' });
        }, 15 * 1000);
      }, 5100);
      this.showEnvironmentTimeout = setTimeout(() => {
        if (!this.state.autoRotate) return;
        this.setState({ element: 'environment' });
        this.showEnvironmentInterval = setInterval(() => {
          if (!this.state.autoRotate) return;
          this.setState({ element: 'environment' });
        }, 15 * 1000);
      }, 10100);
      this.setState({ animationFinished: true });
    }
  };
  showConcept () {
    this.setState({ view: 'concept' }, this.update);
  }
  renderText () {
    if (this.state.view === 'concept') {
      const productionClass = this.state.element === 'production' ?
        'block visible' : 'block';
      const eatingClass = this.state.element === 'eating' ?
        'block visible' : 'block';
      const environmentClass = this.state.element === 'environment' ?
        'block visible' : 'block';
      return (
        <div className="text">
          <div className="pager left" onClick={ this.pageLeft.bind(this)}>
            <DropDownIcon />
          </div>
          <div data-cy="gbi-venn-text-production" className={ productionClass }>
            <h4 className="header">Production Quality</h4>
            <h4 className="sub-header">
              <span className="who">The Farmer</span>
            </h4>
            <p>{ getFactorDef('production') }</p>
          </div>
          <div className={ eatingClass }>
            <h4 className="header">Eating Quality</h4>
            <h4 className="sub-header">
              <span className="who">The Consumer</span>
            </h4>
            <p>{ getFactorDef('eating') }</p>
          </div>
          <div className={ environmentClass }>
            <h4 className="header">Environment Quality</h4>
            <h4 className="sub-header">
              <span className="who">The Planet</span>
            </h4>
            <p>{ getFactorDef('environment') }</p>
          </div>
          <div className="pager right" onClick={ this.pageRight.bind(this) }>
            <DropDownIcon />
          </div>
        </div>
      );
    }
    return null;
  }
  render () {
    let prodScore; let eatScore; let envScore;
    if (this.props.animal && this.props.scoreDefinitions) {
      prodScore = calculateElementScore(
        this.props.animal,
        'production',
        this.props.scoreDefinitions
      );
      eatScore = calculateElementScore(
        this.props.animal,
        'eating',
        this.props.scoreDefinitions
      );
      envScore = calculateElementScore(
        this.props.animal,
        'environment',
        this.props.scoreDefinitions
      );
    }
    let className = 'qr-venn-mobile ' + this.state.view + '-version';
    if (this.state.view === 'concept' && this.state.element) {
      className += ' ' + this.state.element;
    }
    return (
      <article className={ className } >
        <div className="image" ref={ this.shapesRef }>
          <GBIVennConceptShapes
            shieldRef={ this.shieldRef }
            production={ prodScore }
            eating={ eatScore }
            environment={ envScore }
            setFactor={ this.setSelectedElement.bind(this) } />
        </div>
        { this.renderText() }
      </article>
    );
  }
}

QRVennMobile.propTypes = {
  // score: PropTypes.number,
  animal: PropTypes.object,
  scoreDefinitions: PropTypes.object,
  view: PropTypes.oneOf(['core', 'concept'])
};

export default connect(mapStateToProps)(QRVennMobile);
