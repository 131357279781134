import axios from '../util/api';

class GBIAnimalAPI {
  static incrementAnimalView (id) {
    return axios.put(
      `/admin/cattle/${id}/views`
    );
  }
};

export default GBIAnimalAPI;
