/* eslint-disable max-len */
import React from 'react';

const DownArrow = () => (
  <svg className='down-arrow' viewBox="0 0 72.33 98.16">
    <polygon points="72.33 61.94 65.41 55.02 41.06 79.37 41.06 73.91 41.06 0 31.27 0 31.27 73.91 31.27 79.37 6.92 55.02 0 61.94 29.25 91.18 36.17 98.1 36.22 98.16 43.14 91.24 43.09 91.18 72.33 61.94"/>
  </svg>
);

export default DownArrow;
