import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './qr-carbon-hoofprint.scss';
import uuid from 'uuid/v4';
import { Link } from 'react-router-dom';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import CarbonHoofPrintSmall from '../../util/logos/CarbonHoofprintSmall';

const formatSequestation = num => {
  try {
    const number = Math.abs(num);
    if (number > -1 && number < 1) {
      if (number > -0.1 && number < 0.1) return '0.0';
      return number.toPrecision(1);
    }
    return number.toPrecision(2).toLocaleString(undefined, {
      maximumFractionDigits: 1
    });
  } catch (error) {
    return '-';
  }
};

class QRCarbonHoofprint extends Component {
  constructor (props) {
    super(props);
    this.state = {
      version: window.innerWidth > 500 ? 'desktop' : 'mobile',
      scaleWidth: window.innerWidth * 0.8,
      visible: false,
      visibleTime: null,
      dragging: false,
      currentAdjustmentX: null,
      maxAdjustment: 400
    };
    this.sequestationId = uuid();
    this.markerRef = React.createRef();
    this.scaleRef = React.createRef();
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchCancel = this.handleTouchCancel.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.update = this.update.bind(this);
    this.sequestation = this.getSequestation();
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    setTimeout(this.handleScroll, 50);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleWindowResize);
  }
  setPosition (sequestation) {
    if (!this.state.visible) return;
    try {
      const pos = (sequestation + 5) * this.state.scaleWidth / 10;
      // console.log('scaleWidth', this.state.scaleWidth);
      if (this.state.version === 'desktop') {
        this.markerRef.current.style = `transform: translateX(${pos}px)`;
        this.scaleRef.current.style = `transform: translateX(0)`;
      } else {
        this.scaleRef.current.style = `transform: translateX(${-pos}px)`;
        this.markerRef.current.style = `transform: translateX(0)`;
      }
    } catch (error) { /* */ }
  }
  handleWindowResize () {
    let scaleWidth = window.innerWidth * 0.8;
    if (scaleWidth > 800) scaleWidth = 800;
    if (scaleWidth < 500) scaleWidth = 500;
    const maxAdjustment = window.innerWidth / 2;
    const version = window.innerWidth > 500 ? 'desktop' : 'mobile';
    this.setState({ scaleWidth, maxAdjustment, version }, this.update);
  }
  handleScroll () {
    try {
      var rect = this.markerRef.current.getBoundingClientRect();
      var markerTop = rect.top;
      var markerBottom = rect.bottom;
      if ((markerTop >= 0 && markerTop < window.innerHeight) ||
        (markerBottom <= window.innerHeight && markerBottom >= 0)) {
        const visibleTime = this.state.visible ?
          this.state.visibleTime : new Date();
        this.setState({ visible: true, visibleTime });
        this.update();
      } else if (this.state.visible === true) {
        this.setState({ visible: false, visibleTime: null });
        window.requestAnimationFrame(() => {
          this.setPosition(0);
        });
      }
    } catch (error) { /* */ }
  }
  update () {
    const runTime = this.state.version === 'desktop' ? 2000 : 3000;
    const sequestionElement = document.getElementById(this.sequestationId);
    if (!sequestionElement) {
      return window.requestAnimationFrame(this.update);
    }
    const now = new Date();
    const timePassed = now - this.state.visibleTime;
    const rawScore =
      Math.pow((timePassed / runTime), 0.30) * (this.sequestation + 5) - 5;
    const currentScore = Math.round(rawScore * 10) / 10;
    // console.log(currentScore);
    if (currentScore < this.sequestation) {
      sequestionElement.innerHTML = formatSequestation(rawScore);
      this.setPosition(rawScore);
      // this.markerRef.current.style = this.getMarkerCSS(rawScore);
      // console.log('requesting animation frame');
      window.requestAnimationFrame(this.update);
    } else {
      sequestionElement.innerHTML = formatSequestation(this.sequestation);
      // this.markerRef.current.style = this.getMarkerCSS(this.sequestation);
      this.setPosition(this.sequestation);
    }
  };
  getSequestation () {
    let sequestation = null;
    try {
      // console.log(this.props.animal);
      if (this.props.animal.weight &&
      this.props.animal.environment.carbonSequestation.score >= 6) {
        const carcasWeight = 0.58 * this.props.animal.weight;
        sequestation = 6.65 * carcasWeight / 1000;
      }
    } catch (error) { /* */ }
    return sequestation;
  }
  handleTouchStart (event) {
    const dragStartX = event.changedTouches[0].clientX;
    this.setState({ dragStartX });
  }
  handleTouchMove (event) {
    const touchPosX = event.changedTouches[0].clientX;
    const dragAdjustmentX = touchPosX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('carbon-sequestion-concept');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
  }
  handleTouchEnd (event) {
    const touchPosX = event.changedTouches[0].clientX;
    const dragAdjustmentX = touchPosX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('carbon-sequestion-concept');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
      this.setState({ currentAdjustmentX });
    });
  }
  handleTouchCancel (event) {
    const el = document.getElementById('carbon-sequestion-concept');
    const currentAdjustmentX = this.state.currentAdjustmentX;
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
    this.setState({ dragging: false });
  }
  render () {
    const sequestation = this.getSequestation();
    if (sequestation === null) return null;
    return (
      <article className="carbon-sequestation-scale"
        onTouchStart={ this.handleTouchStart.bind(this) }
        onTouchEnd={ this.handleTouchEnd.bind(this) }
        onTouchMove={ this.handleTouchMove.bind(this) }
        onTouchCancel={ this.handleTouchCancel.bind(this) }>
        <div className="title">
          <CarbonHoofPrintSmall />
          <Link to="/good-beef/carbon-negative-beef"><InfoIconThick /></Link>
        </div>
        <div className="concept"
          data-cy="carbon-sequestion-concept"
          id="carbon-sequestion-concept">
          <div className="marker" ref={ this.markerRef }>
            <div className="ring">
              <div className="number"
                data-cy="carbon-tonnes-number"
                id={ this.sequestationId }>0
              </div>
              <div className="label" data-cy="carbon-tonnes-label">tonnes</div>
            </div>
          </div>
          <div className="scale"
            data-cy="carbon-sequestion-scale"
            ref={ this.scaleRef }>
            <h4 className="label emissions">Net Carbon Emissions</h4>
            <h4 className="label negative">Net Carbon Negative</h4>
            <h4 className="label five">5 tonnes</h4>
            <h4 className="label zero">0</h4>
            <h4 className="label minus-five">5 tonnes</h4>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
          </div>
        </div>
        <div className="caption">
          <h4>
            <span className="first">Grass Fed</span><br/>
            <span className="second">& Regenerative Grazing</span>
          </h4>
          <h4 className="equals">=</h4>
          <h4>
            <span className="first">Carbon Negative</span><br/>
            <span className="second">Beef Cattle</span>
          </h4>
        </div>
      </article>
    );
  }
}

QRCarbonHoofprint.propTypes = {
  animal: PropTypes.object
};

export default QRCarbonHoofprint;
