import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DownArrow from '../../util/icons/components/DownArrow';
import BullSilhouette from '../../util/figures/BullSilhouette';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import PropTypes from 'prop-types';
// import GBIVennConceptShapes from '../../util/figures/GBIVennConceptShapes';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import {
  // calculateElementScore,
  getGradeFromScore,
  getShortGradeDescription
} from '../../admin/scoreDefinitions/util';
import GBIGradeShield from '../../util/logos/GBIGradeShield';
import './qr-top-section.scss';
import TraceabilitySection from './TraceabilitySection';
import KeyFacts from './KeyFacts';
import QRCode from 'qrcode.react';
import CowVideoSection from '../../cows/video/components/CowVideoSection';

const QRTop = ({ animal, match }) => {
  // const shieldRef = useRef();
  // let prodScore; let eatScore; let envScore;
  let gbiScore = null;
  const scoreDefinitions = useSelector(getScoreDefinitions);
  if (animal && scoreDefinitions) {
    // prodScore = calculateElementScore(animal, 'production', scoreDefinitions);
    // eatScore = calculateElementScore(animal, 'eating', scoreDefinitions);
    // envScore = calculateElementScore(animal, 'environment', scoreDefinitions);
    gbiScore = animal.beefQualityIndex;
  }
  let gradeContent = null;
  let video = null;
  if (animal) {
    const band = Math.floor(gbiScore / 10) * 10;
    const bandStr = band + '+';
    const grade = getGradeFromScore(gbiScore);
    const gradeTitle = (
      <h4 className="grade-title">
        <span>{ bandStr }</span>
        <span className="brand">{ grade }</span>
      </h4>
    );
    const gradeText = (
      <p className="grade-text">
        { getShortGradeDescription(grade) || ''}
      </p>
    );
    gradeContent = (
      <Fragment>
        <GBIGradeShield grade={ getGradeFromScore(gbiScore) } />
        { gradeTitle }
        { gradeText }
      </Fragment>
    );
    if (animal.video || animal.orgVideo) {
      const props = {
        video: animal.video || animal.orgVideo,
        dob: animal.dob,
        name: animal.name,
        title: 'Come to our Farm'
      };

      if (animal.orgId === '5d0dee629bf92c2352b9961b') { // duplicated in GBIAnimal reducer
        // eslint-disable-next-line react/prop-types
        props.video.timestamps = {
          'views': [],
          'straplines': [
            { 'timestamp': 2, 'duration': 8, 'label': 'grass fed' },
            { 'timestamp': 12, 'duration': 7, 'label': 'home bred' },
            {
              'timestamp': 21,
              'duration': 7,
              'label': 'high health and welfare'
            },
            { 'timestamp': 30, 'duration': 7, 'label': 'sustainable' },
            { 'timestamp': 39, 'duration': 9, 'label': 'nutritionally rich' }
          ]
        };
      } else if (animal.breed === 'Aberdeen Angus' &&
        animal.environment.pastureVsGrain.score >= 2 &&
        animal.eating.breed.score >= 3) {
        props.subTitle = 'Pedigree Angus Grass-Fed Suckler Beef';
      }

      video = <CowVideoSection { ...props } />;
    }
  }
  const name = animal ?
    <h2 className="animal-name">{ animal.name }</h2>
    : null;
  return (
    <article id="qr-top-section">
      <div className="header">
        <h1>
          <span className="base">From the farmer</span>
          <span className="brand"> to the consumer</span>
          <Link to="/about"><InfoIconThick /></Link>
        </h1>
      </div>
      <div id="animal-headline-info" data-cy="animal-headline-info">
        <a href={ `${match.url}#qr-additional-data-section-scroll-point` }
          className="scroll-link">
          <span className="label">authentication</span>
          <DownArrow />
        </a>
        { name }
        <div className="bull-wrapper">
          <Fragment>
            <div data-cy="qr-code-wrapper">
              <QRCode className="qr-code" value="/" renderAs="svg" />
              <Link to="/about/qr-codes">
                <InfoIconThick testId="qr-codes-info-link"
                  className="qr-info-icon" />
              </Link>
            </div>
          </Fragment>
          <BullSilhouette />
          <div className="verification-section"
            data-cy="qr-verification-section">
            <h5 className="assertion-header">verification</h5>
            <KeyFacts documents={ animal ? animal.documents : null } />
          </div>
          <TraceabilitySection animal={ animal } />
        </div>
      </div>
      { video }
      {/* <div id="qr-venn-overview">
        <a href={ `${match.url}#cow-data-section-scroll-point` }
          className="scroll-link">
          <span className="label">scoring</span>
          <DownArrow />
        </a>
        <GBIVennConceptShapes shieldRef={ shieldRef }
          production={ prodScore }
          eating= { eatScore }
          environment={ envScore }
          total={ gbiScore }
          scoreId="qr-top-venn-score"
          shieldId="qr-top-venn-shield"/>
      </div> */}
      <div id="qr-grade-overview">
        <a href={ `${match.url}#grade-section-scroll-point` }
          className="scroll-link">
          <span className="label">grading</span>
          <DownArrow />
        </a>
        { gradeContent }
      </div>
    </article>
  );
};

QRTop.propTypes = {
  animal: PropTypes.object,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default QRTop;
