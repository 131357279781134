import React from 'react';

/* eslint-disable max-len */
const GoodBeefIndexLogo = () => (
  <svg className="goodbeef-index-logo" viewBox="0 0 492.856 122.227">
    <g>
      <path d="M140.444,21.951a133.885,133.885,0,0,0-137.3,0,133.9,133.9,0,0,0,68.65,103.287A133.9,133.9,0,0,0,140.444,21.951Z" transform="translate(-3.144 -3.011)" fill="#c92f6d"/>
      <g>
        <path d="M209.555,30.9V55.7h-7.173l-.216-5.613q-4.26,6.324-13.853,6.324a22.249,22.249,0,0,1-11.367-2.842,19.319,19.319,0,0,1-7.638-8.278,28.511,28.511,0,0,1-2.737-12.966,27.96,27.96,0,0,1,2.808-12.932,19.725,19.725,0,0,1,7.957-8.384A24.05,24.05,0,0,1,189.377,8.1q7.6,0,12.4,2.77a18.845,18.845,0,0,1,7.566,8.74l-9.023,3.908a9.634,9.634,0,0,0-3.872-5.507,11.9,11.9,0,0,0-6.783-1.883,11.6,11.6,0,0,0-9.523,4.192q-3.41,4.194-3.408,11.936,0,8.031,3.337,12.15t9.806,4.12a13.005,13.005,0,0,0,8.1-2.379,8.174,8.174,0,0,0,3.127-6.928V38.15H187.388V30.9Z" transform="translate(-3.144 -3.011)" fill="#3a3a3a"/>
        <path d="M248.312,24.366q4.8,4.9,4.8,13.571t-4.8,13.569q-4.8,4.9-13.392,4.9t-13.395-4.9q-4.792-4.9-4.794-13.569t4.794-13.571q4.8-4.9,13.395-4.9T248.312,24.366Zm-19.681,5.15q-2.169,2.81-2.167,8.421t2.167,8.419a8.448,8.448,0,0,0,12.576,0q2.167-2.807,2.166-8.419t-2.166-8.421a8.448,8.448,0,0,0-12.576,0Z" transform="translate(-3.144 -3.011)" fill="#3a3a3a"/>
        <path d="M289.97,24.366q4.8,4.9,4.794,13.571T289.97,51.506q-4.8,4.9-13.4,4.9t-13.393-4.9q-4.793-4.9-4.794-13.569t4.794-13.571q4.8-4.9,13.393-4.9T289.97,24.366Zm-19.684,5.15q-2.166,2.81-2.165,8.421t2.165,8.419a8.452,8.452,0,0,0,12.579,0q2.163-2.807,2.163-8.419t-2.163-8.421a8.452,8.452,0,0,0-12.579,0Z" transform="translate(-3.144 -3.011)" fill="#3a3a3a"/>
        <path d="M336.326,4.614V55.7H327.66l-.426-6.608a12.539,12.539,0,0,1-4.658,5.329,12.765,12.765,0,0,1-7.07,1.99q-7.1,0-11.154-4.868T300.3,38.008q0-8.741,4.049-13.642t11.154-4.9a12.526,12.526,0,0,1,6.68,1.74,11.773,11.773,0,0,1,4.4,4.8V4.614ZM324.391,46.179q2.2-2.772,2.2-7.887v-.711q0-5.043-2.2-7.85a7.949,7.949,0,0,0-12.153.035q-2.2,2.843-2.2,8.171t2.2,8.171a7.982,7.982,0,0,0,12.153.071Z" transform="translate(-3.144 -3.011)" fill="#3a3a3a"/>
        <path d="M346.215,8.806h20.536q8.379,0,12.5,3.3t4.126,9.486a10.158,10.158,0,0,1-8.955,10.373v.071a12.078,12.078,0,0,1,7.5,3.659,10.265,10.265,0,0,1,2.666,7.141,11.267,11.267,0,0,1-4.4,9.484Q375.774,55.7,367.46,55.7H346.215ZM365.4,28.486q7.812,0,7.815-6.038t-7.815-6.04h-9.665V28.486Zm1.206,19.824q3.911,0,5.864-1.6a5.589,5.589,0,0,0,1.951-4.583,5.486,5.486,0,0,0-1.951-4.547q-1.959-1.562-5.864-1.562H355.738V48.31Z" transform="translate(-3.144 -3.011)" fill="#c92f6d"/>
        <path d="M424.977,41.631H399.824q1.489,7.532,8.879,7.531a10.081,10.081,0,0,0,5.048-1.136,6.064,6.064,0,0,0,2.767-3.269l7.956,2.913a12.564,12.564,0,0,1-5.716,6.5A20.449,20.449,0,0,1,408.7,56.41q-8.8,0-13.643-4.868t-4.83-13.605q0-8.74,4.688-13.606t13.217-4.867q8.238,0,12.68,4.76t4.445,13.57A25.263,25.263,0,0,1,424.977,41.631ZM402.413,28.7a10.324,10.324,0,0,0-2.66,6.109h16.552a10.3,10.3,0,0,0-2.7-6.074,7.58,7.58,0,0,0-5.544-2.1A7.713,7.713,0,0,0,402.413,28.7Z" transform="translate(-3.144 -3.011)" fill="#c92f6d"/>
        <path d="M465.208,41.631H440.056q1.5,7.532,8.885,7.531a10.057,10.057,0,0,0,5.042-1.136,6.068,6.068,0,0,0,2.772-3.269l7.957,2.913a12.575,12.575,0,0,1-5.722,6.5,20.409,20.409,0,0,1-10.049,2.239q-8.814,0-13.643-4.868t-4.83-13.605q0-8.74,4.688-13.606t13.217-4.867q8.238,0,12.68,4.76t4.439,13.57A25.133,25.133,0,0,1,465.208,41.631ZM442.651,28.7a10.321,10.321,0,0,0-2.666,6.109h16.558a10.3,10.3,0,0,0-2.7-6.074,7.573,7.573,0,0,0-5.545-2.1A7.7,7.7,0,0,0,442.651,28.7Z" transform="translate(-3.144 -3.011)" fill="#c92f6d"/>
        <path d="M484.491,20.174h11.225v7.247h-10.8V55.7h-9.735V27.421H469V20.174h6.178V15.912q0-5.826,3.623-8.917T488.611,3.9a17.425,17.425,0,0,1,4.3.5A8.756,8.756,0,0,1,496,5.752l-1.348,6.465A7.269,7.269,0,0,0,490.6,11.15q-6.118,0-6.112,5.471Z" transform="translate(-3.144 -3.011)" fill="#c92f6d"/>
      </g>
      <g>
        <path d="M180.212,121.556h-9.807V74.665h9.807Z" transform="translate(-3.144 -3.011)" fill="#aaa"/>
        <path d="M220.452,88.66q3.521,3.338,3.518,9.591v23.3h-9.733V101.094q0-8.1-6.322-8.1a7.826,7.826,0,0,0-5.793,2.414q-2.382,2.417-2.38,7.389v18.757h-9.733V86.032h8.74l.284,6.322a12.279,12.279,0,0,1,4.9-5.258,14.443,14.443,0,0,1,7.248-1.773A12.906,12.906,0,0,1,220.452,88.66Z" transform="translate(-3.144 -3.011)" fill="#aaa"/>
        <path d="M267.113,70.471v51.085h-8.669l-.426-6.606a12.53,12.53,0,0,1-4.655,5.326,12.767,12.767,0,0,1-7.07,1.992q-7.107,0-11.154-4.871t-4.049-13.531q0-8.742,4.049-13.643t11.154-4.9a12.544,12.544,0,0,1,6.68,1.738,11.8,11.8,0,0,1,4.4,4.8V70.471Zm-11.938,41.565q2.2-2.772,2.2-7.886v-.712q0-5.041-2.2-7.85a7.95,7.95,0,0,0-12.151.035q-2.2,2.842-2.2,8.172t2.2,8.17a7.978,7.978,0,0,0,12.151.071Z" transform="translate(-3.144 -3.011)" fill="#aaa"/>
        <path d="M309.045,107.49H283.893q1.49,7.529,8.879,7.531a10.06,10.06,0,0,0,5.048-1.138,6.065,6.065,0,0,0,2.766-3.269l7.957,2.911a12.557,12.557,0,0,1-5.716,6.5,20.449,20.449,0,0,1-10.055,2.24q-8.805,0-13.643-4.871t-4.83-13.6q0-8.742,4.688-13.607T292.2,85.323q8.238,0,12.68,4.758t4.445,13.57A25.179,25.179,0,0,1,309.045,107.49ZM286.482,94.556a10.339,10.339,0,0,0-2.66,6.112h16.552a10.3,10.3,0,0,0-2.7-6.076,7.584,7.584,0,0,0-5.545-2.1A7.727,7.727,0,0,0,286.482,94.556Z" transform="translate(-3.144 -3.011)" fill="#aaa"/>
        <path d="M338.052,121.556l-8.028-12.931-8.175,12.931H310.984l13.07-17.976L311.623,86.032h11.225l7.6,12.431,7.6-12.431h10.942l-12.58,17.548,12.792,17.976Z" transform="translate(-3.144 -3.011)" fill="#aaa"/>
      </g>
      <polygon points="66.062 79.356 41.377 56.234 45.514 51.816 65.267 70.318 99.021 25.211 103.869 28.837 66.062 79.356" fill="#fff"/>
    </g>
  </svg>
);

export default GoodBeefIndexLogo;
