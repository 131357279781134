import React from 'react';
import PropTypes from 'prop-types';
import GovLogo from '../../../img/logo/gov-uk-logo.jpg';
import DownloadIcon from '../../util/icons/components/DownloadIcon';
import './traceability-section.scss';

const TraceabilitySection = ({ animal }) => {
  if (!animal || !animal.tag) return null;
  const passport = animal && animal.documents && animal.documents.ownership ? (
    <a href={ animal.documents.ownership.src }
      target="_blank"
      rel="noopener noreferrer"
      data-cy="animal-passport-link"
      className="passport-download">
      <DownloadIcon />
      <span className="title">view the passport</span>
      <span className="file-type">.PDF</span>
    </a>
  ) : null;
  return (
    <div className="traceability-section">
      <h5 className="assertion-header">traceability</h5>
      <div className="id-description" data-cy="animal-id-label">
        <img className="gov-logo" src={ GovLogo } />
        Animal ID
      </div>
      <div className="tag" data-cy="animal-tag">{ animal.tag }</div>
      { passport }
    </div>
  );
};

TraceabilitySection.propTypes = {
  animal: PropTypes.object
};

export default TraceabilitySection;
