import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRVennMobile from './QRVennMobile';
import PropTypes from 'prop-types';
import QRVennDesktop from './QRVennDesktop';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import './qr-venn-explaination.scss';

const QRVennConcept = ({ animal }) => {
  const [ isMobile, setIsMobile ] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 900);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let content = null;
  if (isMobile) {
    content = <QRVennMobile animal={ animal } view='concept' />;
  } else {
    content = <QRVennDesktop animal={ animal } view="concept" />;
  }
  // if (isMobile) return <QRVennMobile score={ score } view='concept' />;
  // return <QRVennDesktop score={ score } view="concept" />;
  return (
    <article id="venn-explaination" data-cy="venn-explaination">
      <h2>
        Measuring Beef Quality
        <Link to="/about/scoring"><InfoIconThick /></Link>
      </h2>
      { content }
    </article>
  );
};

QRVennConcept.propTypes = {
  animal: PropTypes.object
};

export default QRVennConcept;
