import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './cow-score-card.scss';
// import '../../cows/components/cow-score-card.scss';
import CloseIcon from '../../util/icons/components/CloseIcon';
import history, { previousPath } from '../../goodBeefIndex/gbiHistory';
import { getScores } from '../../GBIDemo/reducer';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import {
  getSignificance,
  getScoreCard,
  getCategory,
  getName
} from '../../admin/scoreDefinitions/util';

const mapStateToProps = state => ({
  scores: getScores(state),
  scoreDefinitions: getScoreDefinitions(state)
});

class ScoreCard extends Component {
  constructor (props) {
    super(props);
    this.close = this.close.bind(this);
    this.modalRef = React.createRef();
    this.closeRef = React.createRef();
  }
  componentDidMount () {
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  close () {
    if (previousPath() !== null) {
      history.goBack(); // scrolls the page back
    } else {
      const cowURL = this.props.match.url.split('/scorecard')[0];
      history.push(cowURL);
    }
  }
  renderScores () {
    const attribute = this.props.match.params.attribute;
    const category = getCategory(attribute, this.props.scoreDefinitions);
    return getScoreCard(attribute, this.props.scoreDefinitions).map(score => {
      let className = 'score';
      try {
        if (score.score === this.props.animal[category][attribute].score) {
          className += ' active';
        }
      } catch (error) { /* */ }
      return (
        <li key={score.score} className={ className }>
          <div className='wrapper'>
            <div className='number'>{ score.score }</div>
            <div className='text'>{ score.definition }</div>
          </div>
        </li>
      );
    });
  }
  render () {
    const attribute = this.props.match.params.attribute;
    const title = getName(attribute, this.props.scoreDefinitions);
    return (
      <div id='cow-score-card'
        data-cy={ `score-card-${this.props.match.params.attribute}` }>
        <article className='content' ref={this.modalRef}>
          <div
            className="close-wrapper"
            ref={this.closeRef}
            onClick={ this.close }>
            <CloseIcon testId="score-card-close-icon"/>
          </div>
          <h4 className='mini-title'>what does it mean?</h4>
          <h1 className='title'>{ title }</h1>
          <p className='significance'>
            { getSignificance(
              this.props.match.params.attribute,
              this.props.scoreDefinitions) }
          </p>
          <ul className='scores'>
            { this.renderScores() }
          </ul>
        </article>
      </div>
    );
  }
}

ScoreCard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      attribute: PropTypes.string.isRequired
    })
  }),
  scores: PropTypes.object,
  animal: PropTypes.object,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(ScoreCard);
