import React, { Component } from 'react';
import { connect } from 'react-redux';
import './qr-venn-desktop.scss';
import PropTypes from 'prop-types';
import {
  getFactorDef,
  calculateElementScore } from '../../admin/scoreDefinitions/util';
import GBIVennConceptShapes, {
  getTriggerAreaTop,
  getTriggerAreaBottom
} from '../../util/figures/GBIVennConceptShapes';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

/* eslint-disable max-len */
class QRVennDesktop extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: props.view || 'core', // 'core' or 'concept'
      factorHighlighted: null,
      productionVisible: false,
      eatingVisible: false,
      environmentVisible: false
    };
    this.sheildRef = React.createRef();
    this.shapesRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.update = this.update.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowResize);
    this.startTimeout = (() => {
      this.handleScroll();
      this.handleWindowResize();
    }, 500);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.startTimeout) clearTimeout(this.startTimeout);
    if (this.t1) clearTimeout(this.t1);
    if (this.t2) clearTimeout(this.t2);
    if (this.t3) clearTimeout(this.t3);
    if (this.t4) clearTimeout(this.t4);
  }
  getScore () {
    if (this.props.animal) {
      return this.props.animal.beefQualityIndex || 120;
    }
  }
  getShield () {
    if (!this.shield) {
      this.shield = document.getElementById('gbi-venn-concept-shield');
    }
    return this.shield;
  }
  getScoreElement () {
    if (!this.scoreElement) {
      this.scoreElement = document.getElementById('gbi-circles-concept-score');
    }
    return this.scoreElement;
  }
  handleWindowResize () {
    if (this.shapesRef.current) {
      const rect = this.shapesRef.current.getBoundingClientRect();
      this.triggerTop = getTriggerAreaTop(rect);
      this.triggerBottom = getTriggerAreaBottom(rect);
    } else {
      this.triggerTop = 0;
      this.triggerBotom = 0;
    }
  }
  handleScroll () {
    try {
      if (!this.triggerTop || !this.triggerBottom) this.handleWindowResize();
      if ((this.triggerTop >= window.scrollY) &&
      (this.triggerBottom <= (window.innerHeight + window.scrollY))) {
        this.setState({ visible: true, visibleTime: new Date() });
        window.removeEventListener('scroll', this.handleScroll);
        this.update();
      }
    } catch (error) { /* */ }
  }
  update () {
    const runTime = 4000;
    const scoreElement = this.getScoreElement();
    if (!scoreElement) {
      return window.requestAnimationFrame(this.update);
    }
    const now = new Date();
    const timePassed = now - this.state.visibleTime;
    const rawScore = (timePassed / runTime) * this.getScore();
    const currentScore = Math.round(rawScore);
    if (currentScore < this.getScore()) {
      scoreElement.innerHTML = currentScore;
      window.requestAnimationFrame(this.update);
    } else {
      scoreElement.innerHTML = this.getScore();
      if (this.state.view !== 'concept') return;
      this.t1 = setTimeout(() => this.setState({
        productionVisible: true,
        factorHighlighted: 'production'
      }), 500);
      this.t2 = setTimeout(() => this.setState({
        eatingVisible: true,
        factorHighlighted: 'eating'
      }), 2100);
      this.t3 = setTimeout(() => this.setState({
        environmentVisible: true,
        factorHighlighted: 'environment'
      }), 4100);
      this.t4 = setTimeout(() => this.setState({
        factorHighlighted: null
      }), 6800);
    }
  };
  showConcept () {
    this.setState({ view: 'concept' }, this.update);
  }
  renderText () {
    if (this.state.view === 'concept') {
      return (
        <div className="text">
          <div data-cy="gbi-venn-text-production"
            className={ this.state.productionVisible ? 'block production-block visible' : 'block production-block' }>
            <div className="circle"></div>
            <div className="line"></div>
            <h4 className="header">Production</h4>
            <h4 className="sub-header">
              Quality <span className="who">- The Farmer</span>
            </h4>
            <p>{ getFactorDef('production') }</p>
          </div>
          <div className={ this.state.eatingVisible ? 'block eating-block visible' : 'block eating-block' }>
            <div className="circle"></div>
            <div className="line"></div>
            <h4 className="header">Eating</h4>
            <h4 className="sub-header">
              Quality <span className="who">- The Consumer</span>
            </h4>
            <p>{ getFactorDef('eating') }</p>
          </div>
          <div className={ this.state.environmentVisible ? 'block environment-block visible' : 'block environment-block' }>
            <div className="circle"></div>
            <div className="line"></div>
            <h4 className="header">Environment</h4>
            <h4 className="sub-header">
              Quality <span className="who">- The Planet</span>
            </h4>
            <p>{ getFactorDef('environment') }</p>
          </div>
        </div>
      );
    }
    return null;
  }
  render () {
    let prodScore; let eatScore; let envScore;
    if (this.props.animal && this.props.scoreDefinitions) {
      prodScore = calculateElementScore(
        this.props.animal,
        'production',
        this.props.scoreDefinitions
      );
      eatScore = calculateElementScore(
        this.props.animal,
        'eating',
        this.props.scoreDefinitions
      );
      envScore = calculateElementScore(
        this.props.animal,
        'environment',
        this.props.scoreDefinitions
      );
    }
    let className = 'qr-venn-desktop ' + this.state.view + '-version';
    if (this.state.factorHighlighted) className += ' ' + this.state.factorHighlighted;
    return (
      <article className={ className } >
        <div className="image" ref={ this.shapesRef }>
          <GBIVennConceptShapes
            shieldRef={ this.shieldRef }
            production={ prodScore }
            eating={ eatScore }
            environment={ envScore } />
        </div>
        { this.renderText() }
      </article>
    );
  }
}

QRVennDesktop.propTypes = {
  animal: PropTypes.object,
  scoreDefinitions: PropTypes.object,
  view: PropTypes.oneOf(['core', 'concept'])
};

export default connect(mapStateToProps)(QRVennDesktop);
